<template>
  <SubPageWrapper class="expirations">
    <ContentBlock>
      <div class="subpage__title heading-3-sb grey-100">
        <template>
          {{ $t('ViewExpirations.Ui.:Caption') }}
        </template>
      </div>
      <div class="subpage__actions">
        <SlSearchInput
          v-model="search"
          class="w-280"
          @submit="fetchTable"
        />
        <SlButton
          variant="secondary"
          color="grey"
          @click="handleExportToXlsx"
        >
          {{ $t('Main.Ui.acChooseExportToXlsx') }}
        </SlButton>
      </div>
      <div class="subpage__table-wrapper">
        <div class="subpage__table">
          <SlTable
            id="batchesOnHand"
            :headers="tableHeaders"
            :rows="tableRows"
            unique-key="id"
            header-unique-key="key"
            header-name-key="name"
            header-type-key="type"
            :max-height="1000"
            :value-parser="parseValue"
            :loading="loading"
            :initial-col-sizes="[180, 350, 200]"
            api-sortable
            :sort-by="colClass"
            :sort-order="sortOrder"
            :total-rows-count="totalRowsCount"
            :current-page="currentPage"
            :per-page="perPage"
            :inner-no-data="!!search"
            pagination
            num-col
            @page-change="handlePaginationChange"
            @per-page-change="handlePerPageChange"
            @sort="handleSort"
            @contextmenu:clipboard="(cb) => cb()"
          >
            <template #loader>
              <SlOverlay :show="loading" />
            </template>

            <template #expirationDate="slotScope">
              <div class="sl-table__cell-text">
                {{ getDateValue(slotScope.cellValue) }}
              </div>
            </template>

            <template #no-data>
              <SlNoData>
                <template #image>
                  <icon
                    :data="noDataMeta.icon"
                    :class="{
                      'illustration-md': !search,
                      'size-48': search,
                      'fill-off': search
                    }"
                  />
                </template>
                <template #title>
                  {{ noDataMeta.title }}
                </template>
                <template #text>
                  {{ noDataMeta.text }}
                </template>
              </SlNoData>
            </template>
          </SlTable>
        </div>
      </div>
    </ContentBlock>
  </SubPageWrapper>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { tableConfig } from '@/mixins/tableConfig';
import { fileSaver } from '@/mixins/webAPI';
import { dateByLocaleKey, localeDateKeys } from '@/helpers/locale/localeDate';

export default {
  name: 'BatchesOnHand',
  mixins: [tableConfig, fileSaver],
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapState({
      tree: (state) => state.demand.tree.tree,
      table: (state) => state.item.batchesOnHand.expirations || [],
      tableConfig: (state) => state.item.batchesOnHand.tableConfig
    }),
    tableHeaders() {
      return this.table.headers || [];
    },
    tableRows() {
      return this.table.rows || [];
    },
    totalRowsCount() {
      return this.table.totalRowsCount;
    },
    currentPage() {
      return this.table.currentPage;
    },
    noDataMeta() {
      if (this.search) {
        return {
          icon: require('@icons/style_no_search_results_double.svg'),
          title: this.$t('Web.NoData.SearchTitle'),
          text: this.$t('Web.NoData.SearchText')
        };
      }

      return {
        icon: require('@icons/illustration/no-table-rows.svg'),
        title: this.$t('Web.NoData.DefaultTitle')
      };
    }
  },
  beforeMount() {
    this.fetchTable();
  },
  methods: {
    ...mapActions({
      fetchBatchesOnHand: 'item/batchesOnHand/fetchBatchesOnHand',
      updateTableConfig: 'item/batchesOnHand/updateTableConfig',
      exportBatchesOnHandToXlsx: 'item/batchesOnHand/exportBatchesOnHandToXlsx',
      fetchTree: 'demand/tree/fetchTree'
    }),
    async fetchTable() {
      try {
        this.loading = true;

        if (!this.tree?.length) {
          await this.fetchTree();
        }

        await this.fetchBatchesOnHand();
      } finally {
        this.loading = false;
      }
    },
    // table configuration
    parseValue(cellValue) {
      if (typeof cellValue === 'string') {
        return cellValue;
      }

      return cellValue.val || '';
    },
    getDateValue(cellValue) {
      const dateString = dateByLocaleKey(cellValue.val, localeDateKeys.YMD);

      return dateString || '';
    },
    handlePaginationChange(page) {
      this.page = page;

      this.fetchTable();
    },
    handlePerPageChange(pageSize) {
      this.perPage = pageSize;
      this.page = 1;

      this.fetchTable();
    },
    async handleSort({ callback }) {
      const { key, order } = callback();

      this.sortOrder = order;
      this.colClass = key;

      try {
        this.loading = true;

        await this.setSortingParams({
          config: this.tableConfig,
          params: {
            table: 'expirations'
          }
        });
      } finally {
        this.loading = false;
      }
    },
    handleExportToXlsx() {
      this.saveFile(this.exportBatchesOnHandToXlsx);
    }
  }
};
</script>

<style lang="scss" scoped>
.subpage__table-wrapper {
  height: calc(100% - 108px);
}
</style>
